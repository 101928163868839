import React from "react";
import {
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  media7,
  media8,
  media9,
  media10,
  media11,
  media12,
  media13,
  media14,
  media15,
  media16,
  media17,
  media18,
} from "../Images/MEDIA";
import Loader from "./Loader";

const images = [
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  media7,
  media8,
  media9,
  media10,
  media11,
  media12,
  media13,
  media14,
  media15,
  media16,
  media17,
  media18,
];

const Work = () => {
  return (
    <div className="w-full h-full p-4">
      <div className="grid lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 gap-3 p-4">
        {images.map((x, index) => (
          <div className="rounded-xl group relative shadow-card hover:shadow-cardhover card">
            <img
              className="w-full h-auto object-cover md:rounded-xl"
              src={x}
              alt={x}
              key={index}
            /> 
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
