import "./App.css";
import React, { useState, useEffect } from "react";
import InformationLinks from "./components/InformationLinks";
import Information from "./components/Information";
import { Link, BrowserRouter, Route, Routes, NavLink } from "react-router-dom";
import TobaccoRyan from "./components/TobaccoRyan";
import Work from "./components/Work";

const darkBackground = "bg-black text-white duration-500";
const whiteBackground = "bg-white text-black duration-500";
const activeBackground = "bg-pink-700";

const informationLinks = [
  "Contact",
  "Biography",
  "Shows",
  "Music Visuals",
  "Downloads Links",
];

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <TobaccoRyan />,
  },
  {
    path: "/work",
    main: () => <Work />,
  },
  {
    path: "/information",
    main: () => <Information />,
    sidebar: () => (
      <div className="">
        <InformationLinks className="" informationLinks={informationLinks} />
      </div>
    ),
  },
];

function App() {
  const [navSelection, setNavSelection] = useState("Tobacco Ryan");
  const [siteBackground, setSiteBackground] = useState(darkBackground);
  const [isActive, setIsActive] = useState(true);
  const [activeSelection, setActiveSelection] = useState(<TobaccoRyan />);

  const handleSelected = (e) => {
    setNavSelection(e.target.innerText);
    e.target.innerText === "Tobacco Ryan"
      ? setSiteBackground(darkBackground)
      : setSiteBackground(whiteBackground);
    console.dir(e.target);
    console.log(e.target.classList);

    //I'm thinking that I can add and subtract and activate based on the word active as a background setter
    console.log(e.target.className.includes("active"));
  };

  const caseWatcher = () => {
    //active display selection
    switch (navSelection) {
      case "Tobacco Ryan":
        setActiveSelection(<TobaccoRyan />);
        break;
      case "Work":
        setActiveSelection(<Work />);
        break;
      case "Information":
        setActiveSelection(<Information />);
        break;
      default:
        setActiveSelection(<TobaccoRyan />);
    }
  };

  useEffect(() => {
    caseWatcher();
  }, [navSelection]);

  return (
    <BrowserRouter>
      <div
        className={`flex h-screen w-full ${siteBackground} box-content md:flex-row flex-col overscroll-contain sticky overflow-hidden`}
      >
        {/* navbar */}
        <div className="flex flex-auto md:border md:border-r-black max-h-fit md:flex-col justify-content items-center sticky">
          <ul className="p-4 w-full max-md:flex max-md:justify-between max-md:items-center">
            <Link activeStyle={{backgroundColor: "rgb(190, 24, 93);"}} to="/">
              <li
                className="hover:bg-pink-700 hover:text-black px-2"
                onClick={handleSelected}
              >
                Tobacco Ryan
              </li>
            </Link>
              <Link to="/work">
            <li
              className="hover:bg-pink-700 hover:text-black px-2"
              onClick={handleSelected}
            >
              Work
            </li>
              </Link>
              <Link to="/information">
            <li
              className="hover:bg-pink-700 hover:text-black px-2"
              onClick={handleSelected}
            >
              Information
            </li>
              </Link>
          </ul>
          {navSelection === "Information" && (
            <div className="">
              <InformationLinks
                className=""
                informationLinks={informationLinks}
              />
            </div>
          )}
        </div>

        {/* Main Content that switches based on navbar selection */}
        <div className="flex flex-auto w-full h-screen overflow-y-scroll box-border overscroll-contain sticky">
          <div className="bg-slate-300 w-full h-max sticky overflow-y-scroll">
            <div className="max-h-fit overflow-y-hidden snap-y">
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={<route.main />}
                  />
                ))}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
