import React from 'react'

const InformationLinks = ({ informationLinks }) => {

  const handleClickScroll = ( id ) => {
    const tagID = document.getElementById(`${id}`);
    if (tagID) {
      tagID.scrollIntoView({ behavior: 'smooth', block: 'start'})
    }
  }
  

  return (
    <nav className=''>
        <ul className='p-4 w-full max-md:hidden'>
        {informationLinks.map((link) => (
          <li key={link}
          className="hover:bg-pink-700 px-2">
          <div
          onClick={e => handleClickScroll(e.target.innerText)}
          style={{cursor: 'pointer'}}>{link}</div>
          </li>
        ))}
    </ul>
    </nav>
  )
}

export default InformationLinks