import React from "react";
import Spline from "@splinetool/react-spline";

const TobaccoRyan = () => {
  return (
    <div
     className=""
     style={{cursor: 'url(hand-drag.png) 20 10, auto'}}
     >
     
      <div className="w-full h-screen">
      <Spline scene="https://prod.spline.design/cQIEhmz9Dz2Tglo4/scene.splinecode" /><Spline scene="https://prod.spline.design/cQIEhmz9Dz2Tglo4/scene.splinecode" />
      </div>
    </div>
  );
};

export default TobaccoRyan;
