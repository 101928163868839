import React from "react";
import portrait from "../Images/Tobacco_portrait.JPG";
import {
  GrApple,
  GrSpotify,
  GrYoutube,
  GrInstagram,
  GrSoundcloud,
  GrAmazon,
} from "react-icons/gr";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const Information = () => {
  return (
    <div className="flex flex-col p-4 max-h-fit overflow-y-auto snap-y">
      <div id="Contact" className="text-black snap-start scroll-m-3">
        <div className="font-mono text-3xl bg-pink-700 max-w-fit p-1.5">
          Contact
        </div>
        <div className="py-4 px-2">
          Instagram: <span>@Tobacco_ryan</span>
        </div>
        <div
          id="imageContainer"
          className="py-4 px-2 w-3/4 max-sm:w-full h-auto object-cover"
        >
          <img src={portrait} alt="Potrait of Tobacco Ryan" />
        </div>
      </div>


      <div id="Biography" className="text-black snap-start scroll-m-2">
        <h3 className="font-mono text-3xl bg-pink-700 max-w-fit p-1.5">
          Biography
        </h3>
        <div className="flex flex-wrap py-4 px-2">
          <p className="bg-slate-50 p-2">
            “I’ve been doing music since a kid. I would say like 7-8 years old.
            Man i miss those high school mixtape days. That’s when i learned the
            power of respect. I gained respect doing what i love. When i
            released my first official project, Pink Tobacco, i was finally able
            to see my light shine. Artist like frank ocean and solange really
            inspired my alternate sound. Your wildest dreams album was me and
            Teruo Rhodes just having fun and experimenting with new sounds. I
            believe there’s a song on there for everyone. I love this journey of
            overcoming doubt, It makes me really dig in deep to channel my inner
            light. In the past i alway thought the light was at the end of the
            tunnel. Yo that sh*t really with in.”
          </p>
        </div>
      </div>

      <div id="Shows" className="text-black py-4 snap-start">
        <h3 className="font-mono text-3xl bg-pink-700 max-w-fit p-1.5">
          Shows
        </h3>
        <div className="py-4 px-2">
          <div className="text-2xl font-medium">Release Party 2019 Recap</div>
          <div>
            <iframe
            className="w-full aspect-video"
              src="https://www.youtube.com/embed/R3t-iW7gPM0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="py-4 px-2">
          <div className="text-2xl font-medium">Release Party 2021 Recap</div>
          <div>
            <iframe
              className="w-full aspect-video"
              src="https://www.youtube.com/embed/9bkfewOBjN8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div id="Music Visuals" className="text-black py-4">
        <h3 className="font-mono text-3xl bg-pink-700 max-w-fit p-1.5">
          Music Visuals
        </h3>
        <div className="overflow-y-scroll px-2">
          <div className="my-4">
            <iframe
              className="w-full aspect-video"
              src="https://www.youtube.com/embed/videoseries?list=PLrVtuq3z5Ga_N3KMip8qWQTeiET1Ezrf5"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div>
            <iframe
              className="w-full aspect-video"
              src="https://www.youtube.com/embed/IUQS7bmvGVA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div id="Downloads Links" className="text-black snap-start mb-10">
        <h3 className="font-mono text-3xl bg-pink-700 max-w-fit p-1.5">
          Links
        </h3>
        <div className="flex flex-wrap px-2">
          <ol className="w-full flex flex-col justify-center items-center lg:grid lg:grid-cols-2 lg:gap-2">
            <a
            className="w-full" href="https://music.apple.com/us/artist/tobacco-ryan/1305914932">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 border bg-white px-2 rounded my-2 justify-center items-center w-full">
                <GrApple /> Apple Music
              </li>
            </a>
            <a className="w-full" href="https://open.spotify.com/artist/5quuJTU1v0t8ObC0qwwHx0">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 bg-green-500 px-2 rounded my-2 justify-center items-center w-full">
                <GrSpotify /> Spotify
              </li>
            </a>
            <a className="w-full" href="https://www.instagram.com/tobacco_ryan/?hl=en">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 bg-gradient-to-r from-blue-600 to-pink-700 rounded my-2 w-full justify-center items-center text-white">
                <GrInstagram /> Instagram
              </li>
            </a>
            <a className="w-full" href="https://www.youtube.com/@The11thPower">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 bg-red-600 rounded my-2 justify-center items-center w-full">
                <GrYoutube /> Youtube
              </li>
            </a>
            <a className="w-full" href="https://soundcloud.com/user-374637295">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 bg-orange-500 rounded my-2 justify-center items-center w-full">
                <GrSoundcloud /> Soundcloud
              </li>
            </a>
            <a className="w-full" href="https://music.amazon.com/artists/B0773T4JW6/tobacco-ryan">
              <li className="lg:rounded-full flex gap-1.5 text-2xl py-2 bg-slate-700 text-white rounded my-2 justify-center items-center w-full">
                <GrAmazon /> Amazon Music
              </li>
            </a>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Information;
